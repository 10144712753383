<!-- <div *ngIf="!ready" style="background-color:#030306;height:100%;width:100%;"></div> -->
<div class="wrapper">
    <dwolla-personal-vcr 
        email="Ben"
        firstName="Ben"
        lastName="Ben"
        address1="Ben"
        address="Ben"
        address2="Ben"
        address_1="Ben"
        city="Austin"
        state="TX"
        zip="71291"
        terms="www.yourterms.com" 
        privacy="www.yourprivacy.com" 
        [attr.customerId]="getCookie('_cid')"
        [attr.correlationId]="getCookie('_uid')">
    </dwolla-personal-vcr>
</div>