import { ActivatedRoute, Params } from '@angular/router';
import { element } from 'protractor';
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';

@Component({
  templateUrl: './personal.test.component.html',
  styleUrls: ['./personal.component.scss']
})
export class PersonalVerificationTestComponent implements OnInit {
  root: any;
  infoObserver: MutationObserver = new MutationObserver(mutationRecords => {
    mutationRecords.forEach(mutationRecord => {
      if ((mutationRecord.target as any)?.style?.display == 'none') return;
      if (mutationRecord.type == 'characterData') {
        if (this.root.getElementById('dwolla-info-message').textContent != "") {
          console.log(JSON.stringify({ info: this.root.getElementById('dwolla-info-message').textContent }));
        }
        if (mutationRecord.target.textContent.includes('provide your full SSN')) {
          this.root.getElementById('ssn').value = '';
        }
      }
    });
  });
  errorObserver: MutationObserver = new MutationObserver(mutationRecords => {
    mutationRecords.forEach(mutationRecord => {
      console.log(mutationRecord);
      if ((mutationRecord.target as any)?.style?.display == 'none') return;
      if (mutationRecord.type == 'characterData' && this.root.getElementById('dwolla-error-message').textContent != "") {
        console.log(JSON.stringify({ error: this.root.getElementById('dwolla-error-message').textContent }));
      }
    });
  });

  onSubmit() {
    setTimeout(() => {
      var r: any = document.querySelector('dwolla-personal-vcr').shadowRoot;
      console.log(JSON.stringify({
        form: {
          email: r.getElementById('emailInput').value,
          firstName: r.getElementById('firstNameInput').value,
          lastName: r.getElementById('lastNameInput').value,
          address1: r.getElementById('address1').value,
          address2: r.getElementById('address2').value,
          city: r.getElementById('city').value,
          state: r.getElementById('state').value,
          postalCode: r.getElementById('postalCode').value,
          dob: r.getElementById('dob').value,
          type: 'personal'
        }
      }));
    }, 1000);
  }

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      window.addEventListener('DOMContentLoaded', async (event) => {
        this.root = document.querySelector('dwolla-personal-vcr').shadowRoot;

        this.infoObserver.observe(this.root.getElementById('dwolla-info'), { subtree: true, characterData: true });
        this.errorObserver.observe(this.root.getElementById('dwolla-error'), { subtree: true, characterData: true });
        this.root.getElementById('dwolla-personal-vcr-submit').addEventListener("click", this.onSubmit);

        this.setQueryParams(params).then(async () => {
          if (params.testCase == undefined || params.testCase == "") {

            await setTimeout(async () => {
              await this.setValue('ssn', '1234');
              window.scrollTo(0, document.body.scrollHeight);
              this.root.getElementById('termsCheckbox').click();
  
              setTimeout(() => {
                var fContrl = this.root.getElementById("dwolla-personal-vcr-submit");
                fContrl.removeAttribute("disabled");
                window.scrollTo(0, document.body.scrollHeight);
                this.root.getElementById('dwolla-personal-vcr-submit').click();
              }, 100); // change detectors
  
            }, 1000);

          } else if (params.testCase == "retry") {

            console.log('here!1');

            await setTimeout(async () => {
              await this.setValue('ssn', '123456789');
              console.log('here!2')

              window.scrollTo(0, document.body.scrollHeight);
              this.root.getElementById('termsCheckbox').click();

              console.log('here!3')
              setTimeout(() => {
                var fContrl = this.root.getElementById("dwolla-personal-vcr-submit");
                fContrl.removeAttribute("disabled");
                window.scrollTo(0, document.body.scrollHeight);
                this.root.getElementById('dwolla-personal-vcr-submit').click();
              }, 100); // change detectors
              console.log('here!4')
            }, 1000);

          } else if (params.testCase == "document") {

            // var fContrl = this.root.getElementById("dwolla-personal-vcr-submit");
            // fContrl.removeAttribute("disabled");
            // window.scrollTo(0, document.body.scrollHeight);

            setTimeout(() => {
              var fContrl = this.root.getElementById("dwolla-document-submit");
              fContrl.removeAttribute("disabled");
              window.scrollTo(0, document.body.scrollHeight);
              this.root.getElementById('dwolla-document-submit').click();
            }, 100); // change detectors


          }


        });

        // test only
        // await new Promise(resolve => setTimeout(resolve, 10));
        // this.checkBox();
        // this.forceSubmit();
      });
    });
  }
  async setValue(id, text) {
    if (this.root.getElementById(id) == null) return;
    console.log('setting value: ' + id + ' to ' + text);
    var el = this.root.getElementById(id);
    el.value = text;
    el.dispatchEvent(new Event('input'))
    el.dispatchEvent(new Event('change'))
    return;
  }
  async setQueryParams(params: Params) {
    await this.setValue('emailInput', params.email ?? '');
    await this.setValue('firstNameInput', params.firstName ?? '');
    await this.setValue('lastNameInput', params.lastName ?? '');
    await this.setValue('address1', params.address1 ?? '');
    await this.setValue('address2', params.address2 ?? '');
    await this.setValue('city', params.city ?? '');
    await this.setValue('state', params.state ?? '');
    await this.setValue('postalCode', params.postalCode ?? '');
    await this.setValue('dob', params.dob ?? '');
  }
  getCookie(name: string) {
    const ca: Array<string> = document.cookie.split(';');
    const caLen: number = ca.length;
    const cookieName = `${name}=`;
    let c: string;

    for (let i = 0; i < caLen; i += 1) {
      c = ca[i].replace(/^\s+/g, '');
      if (c.indexOf(cookieName) === 0) {
        return c.substring(cookieName.length, c.length);
      }
    }
    return '';
  }
}
