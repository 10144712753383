import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { timingSafeEqual } from 'crypto';

@Component({
  selector: 'app-business',
  templateUrl: './business.component.html',
  styleUrls: ['./business.component.scss']
})
export class BusinessVerificationComponent implements OnInit {

  root: any;
  infoObserver: MutationObserver = new MutationObserver(mutationRecords => {
    mutationRecords.forEach(mutationRecord => {
      if ((mutationRecord.target as any)?.style?.display == 'none') return;
      if (mutationRecord.type == 'characterData') {
        if (this.root.getElementById('dwolla-info-message').textContent != "") {
          console.log(JSON.stringify({ info: this.root.getElementById('dwolla-info-message').textContent }));
        }
        if (mutationRecord.target.textContent.includes('provide your full SSN')) {
          this.root.getElementById('ssn').value = '';
        }
      }
    });
  });
  errorObserver: MutationObserver = new MutationObserver(mutationRecords => {
    mutationRecords.forEach(mutationRecord => {
      console.log(mutationRecord);
      if ((mutationRecord.target as any)?.style?.display == 'none') return;
      if (mutationRecord.type == 'characterData' && this.root.getElementById('dwolla-error-message').textContent != "") {
        console.log(JSON.stringify({ error: this.root.getElementById('dwolla-error-message').textContent }));
      }
    });
  });

  // .dwolla-initial-info-submit
  // .dwolla-business-info-submit
  // .dwolla-business-vcr-submit

  form: any = {};

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      window.addEventListener('DOMContentLoaded', async (event) => {
        this.root = document.querySelector('dwolla-business-vcr').shadowRoot;
        this.setFormAndStartListeners(params);

        this.infoObserver.observe(this.root.getElementById('dwolla-info'), { subtree: true, characterData: true });
        this.errorObserver.observe(this.root.getElementById('dwolla-error'), { subtree: true, characterData: true });

        this.root.getElementById('dwolla-initial-info-submit').addEventListener("click", () => console.log(JSON.stringify({ form: this.form })));
        this.root.getElementById('dwolla-business-info-submit').addEventListener("click", () => console.log(JSON.stringify({ form: this.form })));

        this.setQueryParams(params);
      });
    });
  }
  async setValue(id, text) {
    if (this.root.getElementById(id) == null) return;
    var el = this.root.getElementById(id);
    el.value = text;
    el.dispatchEvent(new Event('input'))
    el.dispatchEvent(new Event('change')) // use for image testing
    return;
  }
  async setQueryParams(params: Params) {
    await this.setValue('emailInput', params.email ?? '');
    await this.setValue('firstNameInput', params.firstName ?? '');
    await this.setValue('lastNameInput', params.lastName ?? '');
    await this.setValue('legalBusinessName', params.legalBusinessName ?? '');
    await this.setValue('businessType', params.businessType ?? '');

    if (this.form.email != undefined && this.form.firstName != undefined && this.form.lastName != undefined && this.form.legalBusinessName != undefined && this.form.businessType != undefined) {
      await this.setValue('doingBusinessAs', params.doingBusinessAs ?? '');
      await this.setValue('address1', params.address1 ?? '');
      await this.setValue('address2', params.address2 ?? '');
      await this.setValue('city', params.city ?? '');
      await this.setValue('state', params.state ?? '');
      await this.setValue('postalCode', params.postalCode ?? '');
      // await this.setValue('businessClassification', params.businessClassification ?? '');
      // await this.setValue('industry', params.industry ?? '');
      await this.setValue('ein', params.ein ?? '');
    }
  }
  getCookie(name: string) {
    const ca: Array<string> = document.cookie.split(';');
    const caLen: number = ca.length;
    const cookieName = `${name}=`;
    let c: string;

    for (let i = 0; i < caLen; i += 1) {
      c = ca[i].replace(/^\s+/g, '');
      if (c.indexOf(cookieName) === 0) {
        return c.substring(cookieName.length, c.length);
      }
    }
    return '';
  }
  setFormAndStartListeners(params) {
    this.form.email = params.email;
    this.root.getElementById('emailInput').oninput = (value) => this.form.email = value.target.value;
    this.form.firstName = params.firstName;
    this.root.getElementById('firstNameInput').oninput = (value) => this.form.firstName = value.target.value;
    this.form.lastName = params.lastName;
    this.root.getElementById('lastNameInput').oninput = (value) => this.form.lastName = value.target.value;
    this.form.legalBusinessName = params.legalBusinessName;
    this.root.getElementById('legalBusinessName').oninput = (value) => this.form.legalBusinessName = value.target.value;
    this.form.businessType = params.businessType;
    this.root.getElementById('businessType').oninput = (value) => this.form.businessType = value.target.value;
    //
    this.form.doingBusinessAs = params.doingBusinessAs;
    this.root.getElementById('doingBusinessAs').oninput = (value) => this.form.doingBusinessAs = value.target.value;
    this.form.address1 = params.address1;
    this.root.getElementById('address1').oninput = (value) => {
      console.log(value.target.value);
      this.form.address1 = value.target.value;
      console.log(JSON.stringify(this.form));
    }
    this.form.address2 = params.address2;
    this.root.getElementById('address2').oninput = (value) => this.form.address2 = value.target.value;
    this.form.city = params.city;
    this.root.getElementById('city').oninput = (value) => this.form.city = value.target.value;
    this.form.state = params.state;
    this.root.getElementById('state').oninput = (value) => this.form.state = value.target.value;
    this.form.postalCode = params.postalCode;
    this.root.getElementById('postalCode').oninput = (value) => this.form.postalCode = value.target.value;
    this.form.ein = params.ein;
    this.root.getElementById('ein').oninput = (value) => this.form.ein = value.target.value;
  }
}
