import { PersonalVerificationTestComponent } from './personal/personal.test.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BusinessVerificationComponent } from './business/business.component';
import { PersonalVerificationComponent } from './personal/personal.component';

const routes: Routes = [
  { path: 'personal', component: PersonalVerificationComponent },
  { path: 'business', component: BusinessVerificationComponent },
  { path: 'personal/test', component: PersonalVerificationTestComponent,

},
  // { path: 'business/test', component: BusinessVerificationComponent },
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
